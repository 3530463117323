import React, { useState, useRef } from 'react';
import Section from '../Section';
import { Container } from '../Grid';
import Icon from '../Icon';
import Modal from '../Modal';
import Slider from "react-slick";
import './styles.scss';

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div 
      className={className}
      onClick={onClick}>
      <Icon name={"arrowright"}/>
    </div>
  );
}


const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div 
      className={className}
      onClick={onClick}>
      <Icon name={"arrowleft"}/>
    </div>
  );
}

const CardFlipCarousel = (props) => {

  const [animation, setAnimation] = useState(null);
  
  const handleFlipBack = (index) => {
    setAnimation(index);
  }

  const handleFlipFront = () => {
    setAnimation(null);
  }
  
  const defaultSettings = {
    accessibility: true,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 970,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  const sliderSettings = { ...defaultSettings };

  return (
    <Section className="card-flip-carousel mb-0">
      <Container>
        <Slider {...sliderSettings}>
          {props.expertList.map(({school, classroom, location, question, answer, author, career, company}, index) => (
              <div className="card-flip">
                <div className={`flip ${animation == index ? 'animation' : ''}`}>
                  <div className="front">
                    {/* front content */}
                      <div className="card">
                        <div className="card-block">
                          <h3 className="card-flip__title">Submitted question:</h3>
                          {question.length > 80 ?
                            <p className="card-flip__question card-flip__question--large">{question}</p>
                            : <p className="card-flip__question">{question}</p>
                          }
                          <div className="card-flip__btn-question">
                            <p>{school}</p>
                            <button onClick={() => handleFlipBack(index)}>
                              <span>See answer</span>
                              <div className="card-flip__icon-question">
                                <Icon name={'arrowright'}/>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="back">
                      {/* back content */}
                      <div className="card">
                        <div className="card-block">
                          <h3 className="card-flip__title">Answer:</h3>
                          <div className="card-block">
                            <p className="card-flip__answer">
                              {answer.length < 220 ? answer : <div className="card-flip__modal">
                                <p className="card-flip__modal-copy mb-0">{answer}</p>
                                <Modal
                                  trigger={
                                    <span>Read More</span>
                                  }
                                >
                                  <h2>Question And Answer</h2>
                                  <h3>Question</h3>
                                  <h4>{question}</h4>
                                  <div className="card-flip__modal-author">
                                    <em>{classroom}</em>
                                    <em>{school}</em>
                                    <em>{location}</em>
                                  </div>
                                  <h3>Answer</h3>
                                  <p>{answer}</p>
                                  <em>{author}</em>
                                </Modal>
                              </div>}
                            </p>
                            <div className="card-flip__author">
                              <p>{author}</p>
                              <p>{career}</p>
                              <p>{company}</p>
                            </div>
                            <div className="card-flip__btn-answer">
                            <button onClick={() => handleFlipFront(index)}>
                              <span>Return</span>
                              <div className="card-flip__icon-answer">
                                <Icon name={'arrowleft'}/>
                              </div>
                            </button>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
        </Slider>
      </Container>
    </Section>
  );
};

export default CardFlipCarousel;