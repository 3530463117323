export const clientList = [
  {
    "question": "What is your favorite part about your job?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "Talking to people.",
    "author": "Susan",
    "career": "Member Representative",
    "company": ""
  },
  {
    "question": "What did you study in college?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "I majored in Marketing and Management, minored in Psychology.",
    "author": "Andrew",
    "career": "VP, Client solutions",
    "company": ""
  },
  {
    "question": "What are the most important soft-skills (non-academic, i.e. communication, creativity etc.) that you use every day in your job?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "The power of persuasion.",
    "author": "Andrew",
    "career": "VP, Client solutions",
    "company": ""
  },
  {
    "question": "What advice would you give a student interested in client services?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "Communication skills, the ability to read people, and the ability to influence are key. Hone those skills as much as possible by taking on leadership roles whenever possible.",
    "author": "Andrew",
    "career": "VP, Client solutions",
    "company": ""
  },
  {
    "question": "What does a Client Solutions Executive do?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "A Client Solutions Executive ensures that clients are benefitting as much as possible from current and future products.",
    "author": "Andrew",
    "career": "VP, Client solutions",
    "company": ""
  },
];
