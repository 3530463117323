export const dataList = [
  {
    "question": "What is your favorite part about working in data science?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "My favorite part about working in data science is thinking through a problem, finding the answer with data analysis and investigation, and then writing code to solve that problem.",
    "author": "Edmond",
    "career": "Director, Data Science",
    "company": ""
  },
  {
    "question": "What types of data do you work with?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "Mainly marketing, advertisement and demographic data.",
    "author": "Mustafa",
    "career": "Data Scientist",
    "company": ""
  },
  {
    "question": "What led you to your current career in data science or Data Engineering?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "I love that the world can be understood a bit more via statistics. You can use data science to help understand how and why people behave, like how they react to changes in price or how their immune system responds to a new infection or predicting how many smartphones we will buy this year.",
    "author": "Edmond",
    "career": "Director, Data Science",
    "company": ""
  },
  {
    "question": "What is a question that a Data Engineer would solve for? What is a question a Data Scientist would solve for?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "A Data Scientist would solve research oriented questions like how likely is it to rain tomorrow considering historical weather. A Data Engineer is more concerned with increasing the speed and efficiency of data retrieval.",
    "author": "Mustafa",
    "career": "Data Scientist",
    "company": ""
  },
  {
    "question": "What is a question that a Data Engineer would solve for? What is a question a Data Scientist would solve for?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "A Data Scientist would consider how to use data to provide an accurate representation of TV viewing to clients. A Data Engineer would consider how to use their knowledge and skills to come up with a better way to continue to gather data on TV viewing.",
    "author": "Susan",
    "career": "Member Representative",
    "company": ""
  },
  {
    "question": "Are there any common misconceptions about your job?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "That I can fix computers",
    "author": "Pradhan",
    "career": "Data Science Engineer",
    "company": ""
  },
  {
    "question": "What did you study in college?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "Computer Science",
    "author": "Unaiza",
    "career": "Project Manager",
    "company": ""
  },
  {
    "question": "What part-time jobs, internships or extracurricular activities would best prepare you for working in data science or Data Engineering?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "Internships in data-driven teams are the best. If these internships aren’t available, seek projects that introduce you to machine learning and data exploration.",
    "author": "Mustafa",
    "career": "Data Scientist",
    "company": ""
  },
  {
    "question": "What advice would you give a student interested in data science or Data Engineering?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "Always keep learning and always keep asking questions!",
    "author": "Edmond",
    "career": "Director, Data Science",
    "company": ""
  },
  {
    "question": "What advice would you give a student working with a data challenge?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "Be patient, simplify and you can crack it.",
    "author": "Unaiza",
    "career": "Project Manager",
    "company": ""
  },
  {
    "question": "What types of data do you work with?",
    "classroom": "",
    "school": "",
    "location": "",
    "answer": "TV Ratings Data, i.e. data around what people watch on TV",
    "author": "Andrew",
    "career": "VP, Client solutions",
    "company": ""
  },
];
