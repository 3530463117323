import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Button from '../components/Button';
import Video from '../components/Video';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Subfooter from '../components/Subfooter';
import Resource from '../components/Resource';
import sampleSize from 'lodash/sampleSize';
import CardFlipCarousel from '../components/CardFlipCarousel';
import QuestionCallout from '../components/QuestionCallout';
import Modal from '../components/Modal';
import ScheduleAVisitForm from '../components/ScheduleAVisitForm';

import { expertList } from '../data/data-experts';
import { clientList } from '../data/data-experts/client';
import { dataList } from '../data/data-experts/data-engineer';

const CareerProfile = ({ data, pageContext: { orderLookup, relatedMap } }) => {
  const [relatedProfiles, setRelatedProfiles] = useState(null);
  const profile = data.allCareerProfilesYaml.edges[0].node;
  const sortIndex = parseInt(orderLookup[profile.parent.name]);
  const maxCareerIndex = parseInt(orderLookup['max']);

  const textOverlay = (
    <div className="career-profile__text_overlay_container">
      <Container fullWidth>
        <p className="career-profile__view-all-link">
          <Link to="/career-profiles">&lsaquo; View All</Link>
        </p>
        <div className="career-profile__text_overlay_info career-profiles__text_overlay_info--full">
          {profile.title}
        </div>
      </Container>
    </div>
  );

  useEffect(() => {
    setRelatedProfiles(
      sampleSize(
        Object.entries(relatedMap).reduce((acc, curr) => {
          if (curr[0] !== profile.parent.name) {
            acc.push(curr[1]);
          }

          return acc;
        }, []),
        2
      )
    );
  }, []);

  return (
    <Layout
      title={`${profile.title} | Career profiles`}
      className="career-profile"
    >
      <Hero
        mediaSource="hero-image-careers-landing@2x.png"
        textOverlay={textOverlay}
      />
      <Section className={'section--intro'}>
        <Container>
          <Row>
            <Column>
              <div className="career-profile__video-wrapper">
                {sortIndex > 1 && (
                  <Link
                    className="career-profile__switch-button career-profile__prev-button"
                    to={`/career-profiles/${orderLookup[sortIndex - 1]}`}
                  >
                    <Icon name="arrowleft" className="callout__icon" />
                  </Link>
                )}
                <Video
                  guidSrc={profile.videoSrc}
                  poster={profile.videoPoster}
                />
                {sortIndex < maxCareerIndex && (
                  <Link
                    className="career-profile__switch-button career-profile__next-button"
                    to={`/career-profiles/${orderLookup[sortIndex + 1]}`}
                  >
                    <Icon name="arrowright" className="callout__icon" />
                  </Link>
                )}
              </div>
            </Column>
          </Row>
          <Row className="mt-2 career-profile__info-container">
            <Column size={8}>
              <h2 className="mb-2">{profile.introHeader}</h2>
              <p dangerouslySetInnerHTML={{ __html: profile.introParagraph }} />
              <h3 id="about" className="mt-1">
                About
              </h3>
              <p dangerouslySetInnerHTML={{ __html: profile.about }} />
              <h3 className="mt-3">Career capture sheet</h3>
              <p>
                Connect students with current data careers by highlighting
                relevance and creating opportunities for reflection to their own
                experiences and goals.
              </p>
              <div className="mb-1">
                <Button
                  to="/sites/default/files/documents/NF-DiscoverData-X9Adaptations-CareerCaptureSheet.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="externallink" />
                  <span className="pl-1">Career exploration activity</span>
                </Button>
              </div>
              <div>
                <Button
                  to="/sites/default/files/Curriculum/NF-DiscoverData-X9Adaptations-CareerCaptureSheet-espanol.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  isSecondary={true}
                >
                  <Icon name="externallink" />
                  <span className="pl-1">Actividad en español</span>
                </Button>
              </div>
            </Column>
            <Column size={3}>
              <Image
                className="profile-quote__image"
                filename={profile.quoteImage}
              />
              <div className="profile-quote mt-2">
                <p
                  className="profile-quote__quote"
                  dangerouslySetInnerHTML={{ __html: profile.quote }}
                />
                <p
                  className="profile-quote__attribution"
                  dangerouslySetInnerHTML={{ __html: profile.quoteAttribution }}
                />
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="mb-0">
        <Container>
          <Row>
            <Column>
              <h3>Ask the experts!</h3>
            </Column>
            <Column size={8}>
              <p>
                Asking questions and being curious is one of the best ways to
                continue growing your knowledge and understanding of any
                subject.{' '}
                <strong>
                  See questions others have asked and enter your question to be
                  answered below!
                </strong>
              </p>
            </Column>
            <Column size={4} className="career-profile__qa-link">
              <Link to="/career-profiles/qa-with-data-experts/">
                View All Q&amp;A
                <Icon name="caratright" marginLeft />
              </Link>
            </Column>
          </Row>
        </Container>
      </Section>
      <CardFlipCarousel
        expertList={
          profile.carousel == 'data-engineer'
            ? dataList
            : profile.carousel == 'client'
            ? clientList
            : expertList
        }
      />
      <QuestionCallout />
      <Section className="pt-0">
        <Container className={'career-profile__images'}>
          <Row className="mb-4">
            <Column size={4}>
              <Image filename="promo-request-a-visit@2x.jpg" />
            </Column>
            <Column size={8}>
              <h2 className="mb-0">
                Virtually connect your class with a data expert
              </h2>
              <p className="text-sans-serif">
                Show students the power of data in action by putting them
                face-to-face with an inspiring real-world data expert, who will
                lead your class through a unique digital lesson.
              </p>
              <Modal
                trigger={
                  <Button>
                    <Icon name="externallink" />
                    <span className="ml-1">Request a visit</span>
                  </Button>
                }
              >
                <ScheduleAVisitForm />
              </Modal>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className={'section--gray mb-0'}>
        <Container>
          <Row>
            <Column size={9}>
              <h2>Share data careers with your class</h2>
            </Column>
          </Row>
          <Row>
            {relatedProfiles && (
              <>
                <Column size={6}>
                  <Resource
                    title={relatedProfiles[0].relatedTitle}
                    className="resource__with-hover"
                    description={relatedProfiles[0].relatedDescription}
                    img={relatedProfiles[0].relatedImage}
                    actions={[
                      {
                        label: 'Watch video',
                        metadata: '',
                        link: relatedProfiles[0].relatedLink,
                        download: '',
                        noIcon: true,
                      },
                    ]}
                    linkResource
                  />
                </Column>
                <Column size={6}>
                  <Resource
                    title={relatedProfiles[1].relatedTitle}
                    className="resource__with-hover"
                    description={relatedProfiles[1].relatedDescription}
                    img={relatedProfiles[1].relatedImage}
                    actions={[
                      {
                        label: 'Watch video',
                        metadata: '',
                        link: relatedProfiles[1].relatedLink,
                        download: '',
                        noIcon: true,
                      },
                    ]}
                    linkResource
                  />
                </Column>
              </>
            )}
          </Row>
        </Container>
      </Section>
      <Section className="pt-0 pb-0 mb-0">
        <Subfooter
          img="footer-educators@2x.jpg"
          content={profile.largeCta}
          icon="icon-subject.svg"
          setDangerously={true}
        />
      </Section>
    </Layout>
  );
};

export default CareerProfile;

export const query = graphql`
  query CareerTemplateQuery($id: String!) {
    allCareerProfilesYaml(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          sortIndex
          about
          aboutPDF
          additionalCareersParagraph
          activateIntro
          largeCta
          introHeader
          related
          relatedImage
          studentActivationPDF
          videoPoster
          videoSrc
          quoteImage
          quote
          quoteAttribution
          carousel
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
