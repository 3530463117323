import React from 'react';
import Icon from '../Icon';
import Section from '../Section';
import { Container, Row, Column } from '../Grid';
import Image from '../Image';
import Question from '../Question';
import AskExpertForm from '../AskExpertForm';
import Modal from '../Modal';
import './styles.scss';

const QuestionCallout = () => {
  return (
    <Section className="pb-4 pt-4">
      <Container fullWidth>
        <Row>
          <Column
            largeOffset={1}
            large={10}
            className="questionCallout__feedback-callout-column pl-0 pr-0"
          >
            <Modal
              trigger={
                <div className="questionCallout__feedback-callout-wrapper">
                  <div className="questionCallout__feedback-callout-text">
                    Do you have a question?<br />Submit it now!
                  </div>
                  <div className="questionCallout__feedback-callout-icon-container">
                  <Image className="callout__icon" filename="icon-arrow.svg"/>
                  </div>
                </div>
              }
            >
              <AskExpertForm />
            </Modal>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default QuestionCallout;
